import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IPublicOpportunityState } from './public-opportunities.model';
import { PublicOpportunitiesStore } from './public-opportunities.store';

@Injectable({ providedIn: 'root' })
export class PublicOpportunitiesQuery extends Query<IPublicOpportunityState> {
  public pendingCount$ = this.select((x) => x.pendingCount);

  public constructor(protected store: PublicOpportunitiesStore) {
    super(store);
  }
}
